//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Fragment } from "vue-fragment";
import { ctrlCart, ctrlPt } from "@/controller";
import { Button, Checkbox, CheckboxGroup, Image, InputNumber, Tag, Message, Icon, MessageBox, Dialog } from "element-ui";
import BaseRadioGroup from "@/components/Base/BaseRadioGroup/BaseRadioGroup.vue";
import BaseCheckboxGroup from "@/components/Base/BaseCheckboxGroup/BaseCheckboxGroup.vue";
export default {
    name: "CartItem",
    components: {
        [Image.name]: Image,
        [Tag.name]: Tag,
        [InputNumber.name]: InputNumber,
        [Button.name]: Button,
        [CheckboxGroup.name]: CheckboxGroup,
        [Checkbox.name]: Checkbox,
        [Icon.name]: Icon,
        [Dialog.name]: Dialog,
        BaseRadioGroup,
        BaseCheckboxGroup,
        Fragment,
    },
    props: {
        cart: {
            type: Object,
            required: true,
        },
        cartList: {
            type: Array,
            required: true,
        },
        isEdit: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            disablePlus: false,
            skuPopoverShow: false, //規格彈窗
            ptInfo: {}, //商品詳情
            skuList: [], //規格選項
            currskuId: null, //當前sku選中項
            skuAdditionList: [], //sku選購列表
            currskuAddition: [], //當前選購選中項
            skuPopoverNumber: 1,
            isChange: false,
        };
    },
    computed: {
        //上架且有庫存
        normalGoods() {
            if (this.Common.isExist(this.cart)) {
                return this.cart.qtyAvail > 0 && this.cart.stat == 2;
            }
        },
        quota() {
            return this.$store.state.base.configInfo.quota;
        },
        //最大購買數量
        max() {
            return function(cart) {
                let quota = null;
                cart.quota ? (quota = cart.quota) : (quota = this.quota);
                return quota || Infinity;
            };
        },
    },
    methods: {
        numberHandleChange(num, item) {
            const limit = item.quota > this.quota ? item.quota : this.quota;
            this.disablePlus = true;
            this.$nextTick(() => {
                if (item.qtyOrd >= item.qtyAvail) {
                    item.qtyOrd = item.qtyAvail;
                    return Message.warning("庫存不足");
                }
                if (num >= limit && limit != 0) {
                    return Message.warning("超出購買數量");
                }
            });

            setTimeout(() => {
                this.updCart(item.cartNbr, item.qtyOrd, item.skuId);
                this.disablePlus = false;
            }, 500);
        },
        //單條刪除
        async delCartByOne(cartNbrs) {
            MessageBox.confirm("是否刪除當前商品?", "提示", {
                confirmButtonText: "確定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    const data = await ctrlCart.delCart({ cartNbrs: cartNbrs.toString() });
                    if (data) this.$emit("getInit", "刪除成功");
                })
                .catch(() => {
                    return false;
                });
        },
        //更新
        async updCart(cartNbr, qtyOrd, skuId) {
            return await ctrlCart.updCart({ cartNbr, qtyOrd, skuId });
        },
        //商品選擇回調
        checkClick(item) {
            //選購商品選中，對應的主商品也會選中
            setTimeout(() => {
                if (item.kind == 0) {
                    for (const cart of this.cartList) {
                        if (item.ptNbr == cart.parPtNbr) {
                            if (cart.qtyAvail > 0 && cart.stat == 2) {
                                this.$set(cart, "checked", item.checked);
                            }
                        }
                    }
                } else {
                    //非編輯刪除模式下=》點擊勾選選購商品=》遍歷購物車列表，查詢與其匹配的商品且是主商品（當前商品.parPtNbr == 遍歷項.ptNbr && 遍歷項.kind==0）=》勾選
                    if (this.isEdit) return;
                    if (!item.checked) return;
                    for (const cart of this.cartList) {
                        if (item.parPtNbr == cart.ptNbr) {
                            if (cart.kind == 0) {
                                this.$set(cart, "checked", true);
                            }
                            break;
                        }
                    }
                }
            }, 300);
        },
        //規格彈窗打開回調事件
        async openSku(cart) {
            //獲取到組件信息，說明skuAdditionList已存在，直接return  不做數據請求
            if (this.$refs.addition) return;
            // //選中選購ID
            const addiId = this.cartList.filter((item) => item.kind == 2 && item.parPtNbr == cart.ptNbr).map((ele) => ele.skuId);

            this.ptInfo = await ctrlPt.getPtInfo({ ptNbr: cart.ptNbr }, {});
            if (this.Common.isExist(this.ptInfo)) {
                //回顯規格選項
                this.skuList = this.ptInfo._skuList;
                this.currskuId = cart.skuId;
                //回顯數量
                this.skuPopoverNumber = cart.qtyOrd;
                //回顯選購
                for (const addi of this.ptInfo.AdditionList) {
                    addi._id = addi.skuId;
                    addi._img = addi.img;
                    addi._name = addi.name;
                    addi._desc = addi.desc;
                    addi._price = addi.price;
                    addi.price_original = addi.origPrice;
                    addi.qtyOrd = this.skuPopoverNumber;
                    addi.parPtNbr = cart.ptNbr;
                    addi.kind = 2;
                }
                //選中選購
                let addition = [];
                for (const addi of this.ptInfo.AdditionList) {
                    for (const id of addiId) {
                        if (addi.skuId == id) {
                            addition.push(addi);
                        }
                    }
                }
                // console.log(addition);
                this.skuAdditionList = this.ptInfo.AdditionList;
                this.currskuAddition = addition;
            }
        },
        //規格彈窗 -- 數量變化
        skuPopovernumChange(num, item) {
            const currItem = this.skuList.find((sku) => sku.skuId == this.currskuId);
            this.$nextTick(() => {
                if (this.skuPopoverNumber >= currItem.qtyAvail) {
                    this.skuPopoverNumber = currItem.qtyAvail;
                    return Message.warning("庫存不足");
                }
                if (num >= this.max) {
                    return Message.warning("超出購買數量");
                }
            });
        },
        //sku彈窗確認回調
        async sureSkuChange() {
            let delResult = [];
            let addResult = [];
            for (const cart of this.cartList) {
                let flag = false;
                for (const addi of this.currskuAddition) {
                    if (cart.skuId == addi.skuId) {
                        flag = true;
                        break;
                    }
                }
                if (!flag && cart.kind == "2") {
                    delResult.push(cart);
                }
            }

            const data = this.updCart(this.cart.cartNbr, this.skuPopoverNumber, this.currskuId);
            if (data) {
                this.$emit("getInit");
                this.skuPopoverShow = false;
            }

            //刪除
            if (this.Common.isExist(delResult)) {
                // console.log("刪除");
                const cartNbrs = delResult.map((item) => item.cartNbr);
                const ret = await ctrlCart.delCart({ cartNbrs: cartNbrs.toString() });
                if (ret) {
                    Message.close();
                    this.$emit("getInit");
                    this.skuPopoverShow = false;
                }
            } else {
                // console.log("新增");
                let selectedAdditidon = this.currskuAddition;

                if (this.Common.isExist(selectedAdditidon)) {
                    const optdObj = {
                        cpgNbr: this.cart.cpgNbr,
                        ptNbr: this.cart.ptNbr,
                        qtyOrd: this.skuPopoverNumber,
                        kind: this.cart.kind,
                        skuId: this.currskuId,
                        cartNbr: this.cart.cartNbr,
                    };
                    addResult.push(optdObj);
                    addResult = [...addResult, ...selectedAdditidon];
                    const ret = await ctrlCart.delCart({ cartNbrs: this.cart.cartNbr });
                    if (ret) this.addCart(addResult);
                }
            }
        },
        async addCart(list) {
            const data = await ctrlCart.addCart(list, false);
            if (data) {
                Message.close();
                this.$emit("getInit");
                this.skuPopoverShow = false;
            }
        },
        //跳轉至商品詳情頁
        goGoods(cart) {
            //下架商品不做跳轉
            if (cart.stat == "0") return false;
            this.$router.push(`/item/index/${cart.ptNbr}`);
        },
        //規格項變化
        skuOptionChange(item) {
            this.currskuId = item.skuId;
            this.skuPopoverNumber = item.qtyAvail > this.skuPopoverNumber ? this.skuPopoverNumber : (this.skuPopoverNumber = item.qtyAvail);
        },
        skuAdditionChangeHandle(item) {
            this.currskuAddition = item;
        },
    },
};
