//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from "vue";
import redict from "@/controller/redirect.js";
import { Image, Checkbox, CheckboxButton, CheckboxGroup } from "element-ui";
Vue.use(Image)
    .use(Checkbox)
    .use(CheckboxButton)
    .use(CheckboxGroup);
export default {
    props: {
        option: {
            type: Array,
            default: () => [],
        },
        //當前選中值
        currCheckboxChange: {
            // type:[Object,String,Number],
            default: "",
        },
    },
    name: "BaseCheckboxGroup",
    components: {},
    data() {
        return {
            currCheckboxChangeChild: "", //當前選中值 同:label
        };
    },
    watch: {
        isLogin: {
            handler: function(val) {
                this.currCheckboxChangeChild = [];
            },
            deep: true,
        },
    },
    computed: {
        isLogin() {
            return this.$store.state.isLogin;
        },
        _item(item) {
            return function(item) {
                return this.Common.isObjectLike(this.currCheckboxChange) ? item : item._id;
            };
        },
    },
    mounted() {
        this.currCheckboxChangeChild = this.currCheckboxChange;
    },
    created() {},
    methods: {
        optionChange(item) {
            this.$emit("optionChange", this.currCheckboxChangeChild, item);
        },
        redirect(val) {
            redict.push(val, this.$route.params.fun);
        },
    },
};
