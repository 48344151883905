//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from "vue";
import { Image,   Radio,
    RadioGroup,
    RadioButton, } from "element-ui";
Vue.use(Image).use(Radio)
    .use(RadioButton)
    .use(RadioGroup);
export default {
    props:{
        option: {
            type: Array,
            default: ()=>[]
        },
        //當前選中值
        currRadioChange: {
            type:[Object,String,Number],
            default:''
        },
        isShowDesc:{
            type:Boolean,
            default:true
        }
    },
    name: "BaseRadioGroup",
    components: {
    },
    data() {
        return {
            currRadioChangeChild:'',//當前選中值 同:label
        };
    },
    watch: {
        // currRadioChange: {
        //     handler: async function(newval, oldval) {
        //         console.log(123);
        //     },
        //     deep:true
        // },
    },
    computed:{
        _item(item){
            return function(item){
                return this.Common.isObjectLike(this.currRadioChange) ? item : item._id
            }
        }
    },
    mounted() {
        this.currRadioChangeChild = this.currRadioChange;
    },
    created() {
    },
    methods: {
        optionChange(item){
            this.$emit('optionChange',item)
        }
    },
};
