//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ctrlCart } from "@/controller";
import { Tabs, TabPane, CheckboxGroup, Checkbox, Button, Tag, MessageBox, Message, Icon } from "element-ui";
import { Null } from "@/components";
import CartItem from "./CartItem";
export default {
    components: {
        [CheckboxGroup.name]: CheckboxGroup,
        [Checkbox.name]: Checkbox,
        [Button.name]: Button,
        [Tag.name]: Tag,
        [Icon.name]: Icon,
        Null,
        CartItem,
    },
    data() {
        return {
            // count: 0, //商品總數
            isEdit: false, //編輯按鈕切換 PC無效
            isAll: false, //全選
            // selectCount: 77, //已選結算商品數量
            cartList: [], //購物車列表
            cpgList: [], //帶活動商品列表
            cartList2: [], //沒有活動的購物車列表
            soldOutCartList: [], //下架商品列表
            // giftList: [], //贈品列表
            // quota: 5,
            totalPrice: 0, //勾選商品總額
            amtDiscFormat: "", //勾選商品優惠-文本
            // quota: process.env.VUE_APP_QUOTA,//最大購買數量

            checked: true,
            skuShow: false,
            promotionPopShow: false,
            radio: "1",
            additionsPopShow: false,
            couponPopShow: false,
            submitbar: false,
            isLoad: true,
        };
    },

    watch: {
        cartList: {
            handler: async function(newVal, oldVal) {
                if (newVal) {
                    const data = await this.handleCheckoutResult();
                    this.totalPrice = data.total - data.amtCpgDisc;
                    data.amtCpgDisc ? (this.amtDiscFormat = `已優惠${data.amtCpgDisc}元`) : (this.amtDiscFormat = "");
                    let giftList = data.giftList;
                    // giftList = [
                    //     {
                    //         cpgNbr: "888888",
                    //         skuId: "777",
                    //         name: "air pods",
                    //         desc: "",
                    //         img: "https://stt.fiedora.com/f/ns/20/04/aae44791129e7378.jpg",
                    //         qtyOrd: 1,
                    //         origPrice: "7777",
                    //     },
                    //     {
                    //         cpgNbr: "j7tv12",
                    //         skuId: "777",
                    //         name: "air pods",
                    //         desc: "",
                    //         img: "https://stt.fiedora.com/f/ns/20/04/aae44791129e7378.jpg",
                    //         qtyOrd: 1,
                    //         origPrice: "7777",
                    //     },
                    // ];
                    //根據cpgNbr 拼接贈品
                    if (this.Common.isExist(giftList)) {
                        for (const cpg of this.cpgList) {
                            for (const gift of giftList) {
                                if (cpg.cpgNbr == gift.cpgNbr) {
                                    //每次循環將cpg.CartList重置至初始元素
                                    cpg.CartList = cpg.CartList.filter((item) => item.kind != 1);
                                    gift.ptName = gift.name;
                                    gift.kind = 1;
                                    gift.cartPrice = gift.price;
                                    delete gift.name, gift.price;
                                    cpg.CartList.push(gift);
                                }
                            }
                        }
                    }

                    //勾選按鈕監聽
                    this.singlechoice();
                }
            },
            deep: true,
        },
        // isEdit() {
        //     //恢復初始
        //     for (const item of this.cartList) {
        //         item.checked = false;
        //     }
        // },
    },
    computed: {
        selectCount() {
            if (this.Common.isExist(this.cartList)) {
                return this.cartList.filter((item) => item.checked).length;
            }
        },
        //數據中checked任一為false
        checkedStateNotOne() {
            if (this.Common.isExist(this.cartList)) {
                if (this.isEdit) {
                    return this.cartList.some((item) => !item.checked);
                }
                return this.cartList.filter((item) => item.qtyAvail > 0 && item.stat == 2).some((item) => !item.checked);
            }
        },

        // 數據中checked全為true
        checkedStateAll() {
            if (this.Common.isExist(this.cartList)) {
                if (this.isEdit) {
                    return this.cartList.every((item) => item.checked);
                }
                return this.cartList.filter((item) => item.qtyAvail > 0 && item.stat == 2).length
                    ? this.cartList.filter((item) => item.qtyAvail > 0 && item.stat == 2).every((item) => item.checked)
                    : false;
            }
        },
        quota() {
            return this.$store.state.base.configInfo.quota;
        },
        //購物車數量
        cartNum() {
            return this.$store.state.cart.cartNum;
        },
    },
    created() {
        this.getInit();
    },
    methods: {
        getInit(notice = null) {
            setTimeout(() => {
                this.getCartCnt();
                this.getCartList();
                if (notice) Message.success(`${notice}`);
            }, 500);
        },
        //獲取購物車商品列表數量
        async getCartCnt() {
            this.$store.dispatch("cart/setCartNum", await ctrlCart.getCartCnt());
        },
        //獲取購物車列表
        async getCartList() {
            const data = await ctrlCart.getCartList();
            if (data) {
                this.cartList = data.CartList;
                this.cpgList = data.CpgCartList;
                this.cartList2 = data.NoCpgCartList;
                this.soldOutCartList = data.SoldOutCartList;
                this.cartList.forEach((item, i) => {
                    if (item.qtyAvail > 0 && item.stat == 2) {
                        this.$set(this.cartList[i], "checked", true);
                    }
                });
            }
        },
        //結算
        onSubmit() {
            // this.submitbar = true;
            // setTimeout(() => {
            //     this.submitbar = false;
            // }, 1500);
            const cart = {
                sourceType: 2,
                PtList: [],
            };
            let cartList = this.cartList.filter((item) => item.checked);
            let optdList = cartList.filter((item) => item.kind == 0);
            let addiList = cartList.filter((item) => item.kind == 2);
            let obj = {};
            for (const optd of optdList) {
                obj = {};
                const Optd = {
                    cartNbr: optd.cartNbr,
                    kind: optd.kind,
                    skuId: optd.skuId,
                    name: optd.optdName,
                    ptName: optd.ptName,
                    imgUrl: optd.img,
                    price: optd.price,
                    qtyOrd: optd.qtyOrd,
                };
                obj.Optd = Optd;
                if (optd.AccesList) obj.Access = optd.AccesList;
                if (optd.cpgNbr) obj.cpgNbr = optd.cpgNbr;
                if (optd.ptNbr) obj.ptNbr = optd.ptNbr;
                cart.PtList.push(obj);
            }

            let ptNbrs = [];
            for (const item of cart.PtList) {
                obj = {};
                item.Addition = [];
                //過濾重複ptNbr
                const ret = ptNbrs.find((pt) => pt == item.ptNbr);
                if (ret) break;
                for (const addi of addiList) {
                    if (item.ptNbr == addi.parPtNbr) {
                        ptNbrs.push(item.ptNbr);
                        obj = {
                            cartNbr: addi.cartNbr,
                            skuId: addi.skuId,
                            name: addi.ptName + "" + addi.optdName,
                            price: addi.price,
                            price_original: addi.cartPrice,
                            img: addi.img,
                            qtyOrd: addi.qtyOrd,
                        };
                        item.Addition.push(obj);
                    }
                }
            }
            console.log(cart);
            ctrlCart.addBuyNow(cart);
            // this.$router.push({ name: "checkoutCart" });
        },
        //全選
        clickSelectAllValue() {
            if (this.isEdit) {
                this.cartList.forEach((item, i) => {
                    this.$set(this.cartList[i], "checked", this.isAll);
                });
            } else {
                this.cartList.forEach((item, i) => {
                    if (item.qtyAvail > 0 && item.stat == 2) {
                        this.$set(this.cartList[i], "checked", this.isAll);
                    }
                });
            }
        },
        //監聽數據 -- 單選
        singlechoice() {
            // 子項全選，全選按鈕true
            if (this.checkedStateAll) {
                this.isAll = true;
            }
            //任一子項不選，全選按鈕false
            if (this.checkedStateNotOne) {
                this.isAll = false;
            }
        },
        //計算總額,優惠返回信息
        async handleCheckoutResult() {
            let total = 0;
            let giftList = [];
            let cartList = this.cartList.filter((item) => item.checked);

            for (const item of cartList) {
                const isHasDisc = cartList.some((item) => item.hasDisc);
                //勾選商品中存在優惠信息，服務端計算金額
                if (isHasDisc) {
                    const data = await this.getCheckoutResultByCart(cartList);
                    giftList = data.GiftList;
                    return {
                        total: data.amtTtl,
                        amtCpgDisc: data.amtCpgDisc,
                        // giftList,
                    };
                }
                //勾選商品中不存在優惠信息，前端計算金額
                total += item.price * Number(item.qtyOrd);
            }
            return { total, amtCpgDisc: 0, giftList };
        },
        //計算優惠信息（贈品、金額）等
        async getCheckoutResultByCart(cartList) {
            const ret = [];
            for (const item of cartList) {
                const obj = {
                    cartNbr: item.cartNbr,
                    qtyOrd: item.qtyOrd,
                };
                ret.push(obj);
            }
            return await ctrlCart.getCheckoutResultByCart(ret);
        },
        //清空所有下架商品
        clearAll() {
            const cartNbrs = this.soldOutCartList.map((ele) => ele.cartNbr);
            MessageBox.confirm("是否清空下架商品?", "提示", {
                confirmButtonText: "確定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    const data = await ctrlCart.delCart({ cartNbrs: cartNbrs.toString() });
                    if (data) this.getInit("操作成功");
                })
                .catch(() => {
                    return false;
                });
        },
        //批量刪除
        async delCart() {
            const cartNbrs = this.cartList.filter((item) => item.checked).map((ele) => ele.cartNbr);
            MessageBox.confirm("是否刪除勾選商品?", "提示", {
                confirmButtonText: "確定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    const data = await ctrlCart.delCart({ cartNbrs: cartNbrs.toString() });
                    if (data) this.getInit("刪除成功");
                })
                .catch(() => {
                    return false;
                });
        },
    },
};
